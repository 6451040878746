import config from "@/config/env";
import { AnalyticsBrowser } from "@segment/analytics-next";
import Cookies from "js-cookie";

export type TrackEventPayload = {
  type: "track";
  event: string;
  properties: Object;
};

const getArticleId = () => {
  const result = document.URL.match(/\/articles\/([\w-]+)/) ?? null;
  if (result) {
    return result[1];
  }
  return result;
};

const getArticleTitle = () => {
  return (
    document.querySelector<HTMLHeadingElement>(".article-title")?.innerText ??
    null
  );
};

export const segmentAnalytics = AnalyticsBrowser.load({
  writeKey: config.segment.writeKey,
});

const isLoggedUser = () => Boolean(Cookies.get("userToken"));

// TODO: get userId in  from context and pass in properties object
// ex.: user_id: Smooch.getUser()?.id ?? null,

export const useDispatchEvent = () => {
  const dispatchAnalycts = (
    payload: MakeOptional<TrackEventPayload, "properties">
  ) => {
    const defaultAnalyticsData = {
      ...payload,
      properties: {
        article_id: getArticleId(),
        article_title: getArticleTitle(),
        path: window.location.pathname ?? null,
        search: window.location.search ?? null,
        title: document.title ?? null,
        url: window.location.href ?? null,
        value: 1,
        event_category: "customer_experience",
        isNewHelpcenter: true,
        isLoggedUser: isLoggedUser(),
        ...payload.properties,
      },
    };

    if (segmentAnalytics) {
      segmentAnalytics.track(defaultAnalyticsData);
    }
  };

  return {
    dispatchAnalycts,
  };
};
