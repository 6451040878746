"use client";

import {
  SnowplowEventSchemas,
  useEventTracker,
} from "@gympass/event-tracker-snowplow";
import { useParams } from "next/navigation";
import { useCallback } from "react";
import Cookies from "js-cookie";

type EventDispatcher = {
  category: string;
  action: string;
} & Record<string, unknown>;

const getAnonymousId = () => {
  const userAnonymousId = Cookies.get("anonymousId");
  if (userAnonymousId) return userAnonymousId;
  else {
    const newId = self.crypto.randomUUID();
    Cookies.set("anonymousId", newId);
    return newId;
  }
};

const useSnowplow = () => {
  const params = useParams<{ region: string; readableId?: string }>();
  const { record } = useEventTracker();

  const sendSnowplowEvent = useCallback(
    ({ ...args }: Omit<SnowplowEventSchemas["action"], "roles">) => {
      if (["development", "staging"].includes(process.env.NODE_ENV)) {
        return;
      }
      const currentDate = new Date();

      const defaultData = {
        occurrence_date: currentDate.toISOString(),
        user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || null,
        language: params.region,
        anonymous_id: getAnonymousId(),
        tagus_user_id: Cookies.get("userId") || null,
        user_roles: Cookies.get("userType")?.split(",") || [],
        user_platform: Cookies.get("userPlatform") || null,
      };

      record("action", { ...defaultData, ...args });
    },
    [record, params.region]
  );

  const eventDispatcher = async ({ ...props }: EventDispatcher) => {
    sendSnowplowEvent({ ...props });
  };

  return {
    eventDispatcher,
  };
};

export default useSnowplow;
