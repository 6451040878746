import Cookies from "js-cookie";
import { deleteUserTab } from "../_server-actions/cookies/userTab";

export const clearUserInfoFromStorage = () => {
  localStorage.removeItem("userId");
  localStorage.removeItem("userToken");
  localStorage.removeItem("userType");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("userPlatform");
  localStorage.removeItem("userRole");
  localStorage.removeItem("userName");

  Cookies.remove("userId");
  Cookies.remove("userToken");
  Cookies.remove("userType");
  Cookies.remove("userEmail");
  Cookies.remove("userPlatform");
  Cookies.remove("userRole");
  Cookies.remove("userName");
  Cookies.remove("anonymousId");
};

export const getKeycloakInstance = (initializer?: {
  authUrl: string;
  clientId: string;
}) => {
  if (!window.keycloakInstance && initializer) {
    const keycloackSettings = {
      realm: "master",
      url: initializer.authUrl,
      clientId: initializer.clientId,
    };
    //@ts-ignore
    window.keycloakInstance = new Keycloak(keycloackSettings);
  }

  return window.keycloakInstance;
};

export const logout = ({
  clientId,
  authUrl,
}: {
  clientId: string;
  authUrl: string;
}) => {
  const keycloak = getKeycloakInstance({
    clientId,
    authUrl,
  });

  if (!window.keycloakInitialized) {
    keycloak.init();
  }

  clearUserInfoFromStorage();

  keycloak.logout({ redirectUri: `${window.location.href}` });
};

export const login = () => {
  if (typeof window !== "undefined" && window.keycloakInstance) {
    deleteUserTab();
    window.keycloakInstance.login();
  }
};
