export type AppConfig = {
  appName: string;
  dd: {
    applicationId: string;
    clientToken: string;
  };
  hotjar: {
    applicationId: string | number;
    version: string | number;
  };
  snowplowUrl: string;
  serverEnv: string;
  api: {
    omnitrixService: {
      url: string;
      cacheTimes: {
        getAllArticles: number;
        getArticle: number;
        getCategoryWithArticles: number;
        getContent: number;
        getNotifications: number;
        getPromotedArticles: number;
      };
    };
  };
  apiBaseUrl: string;
  unleash: {
    baseUrl: string;
  };
  keycloak: {
    authUrl: string;
    clientId: string;
    libUrl: string;
  };
  oldHelpCenter: {
    url: string;
  };
  requesterService: {
    url: string;
  };
  segment: {
    writeKey: string;
  };
  chatbot: {
    url: string;
  };
  userType: {
    "pt-br": UserTypes;
    "en-us": UserTypes;
    "en-gb": UserTypes;
    "it-it": UserTypes;
    "de-de": UserTypes;
    "en-ie": UserTypes;
    "es-es": UserTypes;
    "es-ar": UserTypes;
    "es-mx": UserTypes;
    "es-cl": UserTypes;
  };
};

const env = process.env;

const config: AppConfig = {
  appName: env.NEXT_PUBLIC_APPLICATION_NAME ?? "",
  dd: {
    applicationId: env.NEXT_PUBLIC_DD_APP_ID ?? "",
    clientToken: env.NEXT_PUBLIC_DD_CLIENT_TOKEN ?? "",
  },
  hotjar: {
    applicationId: env.NEXT_PUBLIC_HOTJAR_ID ?? 3835460,
    version: env.NEXT_PUBLIC_HOTJAR_VERSION ?? 6,
  },
  snowplowUrl: env.SNOWPLOW_URL ?? "",
  serverEnv: process.env.APP_ENV ?? "development",
  api: {
    omnitrixService: {
      url: process.env.OMNITRIX_SERVICE_URL ?? "",
      cacheTimes: {
        getAllArticles: 0,
        getArticle: parseInt(
          process.env.OMNITRIX_SERVICE_GET_ARTICLE_CACHE_TIME_IN_SECONDS ?? "0",
          10
        ),
        getCategoryWithArticles: parseInt(
          process.env
            .OMNITRIX_SERVICE_GET_CATEGORY_WITH_ARTICLES_CACHE_TIME_IN_SECONDS ??
            "0",
          10
        ),
        getContent: parseInt(
          process.env.OMNITRIX_SERVICE_GET_CONTENT_CACHE_TIME_IN_SECONDS ?? "0",
          10
        ),
        getNotifications: parseInt(
          process.env
            .OMNITRIX_SERVICE_GET_NOTIFICATIONS_CACHE_TIME_IN_SECONDS ?? "0",
          10
        ),
        getPromotedArticles: parseInt(
          process.env
            .OMNITRIX_SERVICE_GET_PROMOTED_ARTICLES_CACHE_TIME_IN_SECONDS ??
            "0",
          10
        ),
      },
    },
  },
  apiBaseUrl: env.NEXT_PUBLIC_API_BASE_URL ?? "",
  unleash: {
    baseUrl:
      process.env.UNLEASH_BASE_URL ?? "https://unleash.us.stg.gympass.cloud",
  },
  keycloak: {
    authUrl: env.INDETITY_AUTH_URL ?? "",
    clientId: env.INDETITY_CLIENT_ID ?? "",
    libUrl: env.KEYCLOAK_LIB_URL ?? "",
  },
  oldHelpCenter: {
    url: env.NEXT_PUBLIC_OLD_HELPCENTER_URL ?? "",
  },
  requesterService: {
    url: env.REQUESTER_API_URL ?? "",
  },
  segment: {
    writeKey: env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? "",
  },
  chatbot: {
    url: env.CHATBOT_SCRIPT_URL ?? "",
  },
  userType: {
    "pt-br": {
      enduser: process.env.TAB_ENDUSER_PT_BR ?? "",
      partner: process.env.TAB_PARTNER_PT_BR ?? "",
      client: process.env.TAB_CLIENT_PT_BR ?? "",
    },
    "en-us": {
      enduser: process.env.TAB_ENDUSER_EN_US ?? "",
      partner: process.env.TAB_PARTNER_EN_US ?? "",
      client: process.env.TAB_CLIENT_EN_US ?? "",
    },
    "en-gb": {
      enduser: process.env.TAB_ENDUSER_EX_GB ?? "",
      partner: process.env.TAB_PARTNER_EX_GB ?? "",
      client: process.env.TAB_CLIENT_EX_GB ?? "",
    },
    "it-it": {
      enduser: process.env.TAB_ENDUSER_IT_IT ?? "",
      partner: process.env.TAB_PARTNER_IT_IT ?? "",
      client: process.env.TAB_CLIENT_IT_IT ?? "",
    },
    "de-de": {
      enduser: process.env.TAB_ENDUSER_DE_DE ?? "",
      partner: process.env.TAB_PARTNER_DE_DE ?? "",
      client: process.env.TAB_CLIENT_DE_DE ?? "",
    },
    "en-ie": {
      enduser: process.env.TAB_ENDUSER_EN_IE ?? "",
      partner: process.env.TAB_PARTNER_EN_IE ?? "",
      client: process.env.TAB_CLIENT_EN_IE ?? "",
    },
    "es-es": {
      enduser: process.env.TAB_ENDUSER_ES_ES ?? "",
      partner: process.env.TAB_PARTNER_ES_ES ?? "",
      client: process.env.TAB_CLIENT_ES_ES ?? "",
    },
    "es-ar": {
      enduser: process.env.TAB_ENDUSER_ES_AR ?? "",
      partner: process.env.TAB_PARTNER_ES_AR ?? "",
      client: process.env.TAB_CLIENT_ES_AR ?? "",
    },
    "es-mx": {
      enduser: process.env.TAB_ENDUSER_ES_MX ?? "",
      partner: process.env.TAB_PARTNER_ES_MX ?? "",
      client: process.env.TAB_CLIENT_ES_MX ?? "",
    },
    "es-cl": {
      enduser: process.env.TAB_ENDUSER_ES_CL ?? "",
      partner: process.env.TAB_PARTNER_ES_CL ?? "",
      client: process.env.TAB_CLIENT_ES_CL ?? "",
    },
  },
};

export default config;
