"use client";

import { segmentAnalytics } from "@/app/lib/segment";
import Script from "next/script";

const featureEnabled =
  (region: string) =>
  async (feature: string, namespace = "help-center"): Promise<boolean> => {
    const features: Feature[] =
      window.zendeskThemeUtils.featureToggle.features[namespace];

    const toggle: Optional<Feature> = features.find(
      (featureToggle: Feature) => featureToggle.name === feature
    );

    if (!toggle) {
      console.error(
        `Failed to find ${feature} toggle on ${namespace} namespace`
      );
      return false;
    }

    const toggleIncludeList = toggle.strategies.filter(
      (v) => v.name === "includeList"
    )[0];

    return Boolean(toggleIncludeList.parameters.include?.includes(region));
  };

export const initializeLegacyChatbotAdapters = ({
  toggles,
  experiments,
  region,
}: {
  toggles: Feature[];
  experiments: Feature[];
  region: string;
}) => {
  const featureTogglesConstants = {
    FEATURE_TAGUS_ENABLED_REGIONS:
      "dots.help-center.webchat.tagus.enabled-regions",
    FEATURE_MANDATORY_LOGIN_ENABLED_REGIONS:
      "dots.help-center.mandatory-login.enabled-regions",
    FEATURE_UNIFIED_HC_ENABLED_REGIONS:
      "dots.help-center.unified-hc.enabled-regions",
    FEATURE_MANDATORY_LOGIN_WEBFORM_ENABLED_REGIONS:
      "dots.help-center.mandatory-login-webform.enabled-regions",
    FEATURE_STATE_MACHINE_ENABLED_REGIONS:
      "dots.help-center.state-machine.enabled-regions",
  };

  window.zendeskThemeUtils = {
    featureToggle: {
      constants: featureTogglesConstants,
      features: {
        "help-center": toggles,
      },
      featureEnabled: featureEnabled(region),
    },
    keycloakLogin: () => window.keycloakInstance.login(),
  };
  window.analytics = segmentAnalytics;
};

type Props = {
  chatbotUrl: string;
};

export const ChatbotInitializer: React.FC<Props> = ({ chatbotUrl }) => {
  return (
    <>
      <Script
        async
        strategy="lazyOnload"
        type="text/javascript"
        src={chatbotUrl}
      />
    </>
  );
};
